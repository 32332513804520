<template>
  <div class="list">
    <el-collapse accordion>
      <el-collapse-item v-for="itm in listDatas" :key="itm.name" disabled>
        <template slot="title">
          <img
            class="itm-icon"
            :src="
              require(`@/assets/svg/${itm.val == 'true' ? 'done' : 'todo'}.svg`)
            "
          />
          {{ itm.name }}
        </template>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listDatas: [
        { name: "买机票到南京 ✈️", val: "true" },
        { name: "抢票南京-日照火车 🚄 ", val: "true" },
        { name: "等待出发去日照 ⏳", val: "true" },
        { name: "剁椒皮蛋 🥚", val: "true" },
        { name: "虾 🦐", val: "true" },
        { name: "土豆丝 🥔", val: "true" },
        { name: "喝通宵 🍺", val: "true" },
        { name: "第一次看海 🌊", val: "true" },
        { name: "日照森林公园 🌲", val: "" },
      ],
      orShow: false,
      bingVal: "",
    };
  },
};
</script>
<style>
.el-collapse-item__header {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 2px;
  border-bottom: 1px solid #dfdfdf;
  height: 46px !important;
}

.el-collapse {
  border: 1px solid #dfdfdf;
  border-bottom: none;
  border-top: none;
}
</style>
<style lang="less" scoped>
.list {
  .itm-icon {
    width: 18px;
    padding: 0px 12px;
  }

  .edit {
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 56%;
    padding-top: 6px;

    div {
      font-weight: bold;
      border: 1px solid #dfdfdf;
      padding: 2px 16px;
      line-height: 30px;
      border-radius: 15px;
      cursor: pointer;

      img {
        width: 18px;
        padding-left: 6px;
      }
    }

    .done {
      background-color: #39c146;
      color: #fff;
      border-color: #39c146;
    }

    .del {
      background-color: red;
      color: #fff;
      border-color: red;
    }
  }

  .bingCon {
    padding: 0 20px;

    .title {
      padding-bottom: 16px;
      font-weight: bolder;
    }

    .btns {
      display: flex;
      justify-content: space-between;
      padding: 36px 0;
    }
  }
}
</style>